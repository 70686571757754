// Here you can add other styles

@import '@fontsource/pt-sans';
@import '@fontsource/rajdhani';
@import '@fontsource/roboto-condensed';
@import "@fontsource/bayon";

html,
body {
  font-family: 'PT Sans';
  color: #000;
  background-color: #ebedef;
}

.logo {
  font-family: 'Rajdhani';
}

.menu {
  font-family: 'Roboto Condensed';
}

.bayon {
  font-family: 'Bayon';
}

.text-chartreuse {
  color: #d0f224;
}

.btn-chartreuse {
  --cui-btn-bg: #d0f224;
  --cui-btn-hover-bg: #b5d60d;
}
